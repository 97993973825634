import { useEffect, useState } from "react";

export default function Button({ className, children, icon, onClick, prevent, stop, disabled, dense, type = 'button' }) {
	const [styles, setStyles] = useState({});
	const [innerPrevent, setInnerPrevent] = useState(true);
	const [innerStop, setInnerStop] = useState(true);

	useEffect(() => {
		setInnerPrevent(prevent);
		setInnerStop(stop);
		if(prevent === undefined) {
			setInnerPrevent(true);
		}
		if(stop === undefined) {
			setInnerStop(true);
		}
	}, [prevent, stop]);

	useEffect(() => {
		if(icon) {
			setStyles({
				height: '40px',
				width: '40px',
				lineHeight: '40px',
				paddingLeft: 0,
				paddingRight: 0,
			});
		}
		if(dense) {
			setStyles(prev => ({
				...prev,
				height: '30px',
				width: '30px',
			}));
		}
	}, [icon, dense]);

	function handleClick(e) {
		if(disabled) return;

		if(innerPrevent) {
			e?.preventDefault();
		}
		if(innerStop) {
			e?.stopPropagation();
		}
		onClick && onClick();
	}

	return (
		<button
			className={`
				flex items-center justify-center gap-1
				rounded-full italic pl-5 pr-6 py-1
				font-semibold uppercase ${className} ${disabled ? 'opacity-50 cursor-default' : ''}
			`}
			style={styles}
			onClick={handleClick}
			type={type}
		>
			{children}
		</button>
	);
}