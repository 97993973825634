import { useEffect, useState } from 'react';
import { CurrencyFormat } from '../../utils/Text';
import Button from '../button/Button';
import Icon from '../icon/Icon';

const Challenge = ({
		userId,
		challenge, _key,
		streamerView, selected,
		inPoll,
		disabled,
		onReject, onAccept,
		onRemove,
		onVote,
		onPin,
		onIncreaseBid,
	}) => {
	const [accepted, setAccepted] = useState(false);
	const [done, setDone] = useState(false);
	const [dark, setDark] = useState(false);
	const [approvedPercentage, setApprovedPercentage] = useState(null);

	useEffect(() => {
		setDark(done && !inPoll);
	}, [done, inPoll]);

	useEffect(() => {
		setAccepted(false);
		setDone(false);
		if(challenge.status === 'accepted') {
			setAccepted(true);
		}
		else if(challenge.status === 'done') {
			setDone(true);
		}

		if(challenge.poll) {
			if(Object.keys(challenge.poll).length === 0)
				return;
			const total = Object.keys(challenge.poll).length;
			const approved = Object.keys(challenge.poll).filter(key => challenge.poll[key]).length;
			setApprovedPercentage(Math.round((approved / total) * 100));
		}

	}, [challenge, Object.keys(challenge.poll).length]);

	return (
		<div
			className={
				`
					relative border border-gray rounded-2xl p-5
					${(selected || accepted || (done && !dark)) && 'border-primary border-2'}
					${dark && 'bg-black text-white'}
				`
			}
		>
			{
				onPin &&
				<Button onClick={streamerView ? onPin : null} icon className='absolute top-5 right-2'>
					<Icon name='star' size='2rem' className='text-primary' filled={challenge.isPinned}/>
				</Button>
			}

			<div className={`flex gap-2 border-b-2 border-black pb-3 ${dark && 'border-white'}`}>
				<Icon
					name={
						challenge.isPinned ? 'person_raised_hand' :
						selected ? 'check_circle' :
						accepted ? 'verified' :
						done ? 'social_leaderboard' :
							'directions_run'
					}
					size='3rem' className={`text-primary ${dark && 'text-white'}`}
				/>
				<div className='flex flex-col'>
					<span className='text-xl font-semibold italic'>
						{
							selected ? `Selected Challenge #${_key}` :
							accepted ? 'Challenge Accepted' :
							done ? `Challenge #${_key} Done` :
								`Incoming Challenge #${_key}!`
						}
					</span>
					<span className='text-sm text-gray-600'>@{challenge.username} has challenged you!</span>
				</div>
			</div>
			<div className='mt-5 flex flex-col gap-5'>
				<div className='flex justify-between'>
					<div className='flex items-center gap-2'>
						<Icon name='campaign' className={`text-primary ${dark && 'text-white'}`} size='1.5rem'/>
						<div>
							<span className='font-semibold mr-1 uppercase italic'>User Bet:</span>
							<span className=''>{CurrencyFormat(challenge.amount)} USD</span>
						</div>
					</div>
					{
						challenge.isPinned &&
						<div className='bg-primary rounded-full text-white text-sm py-1 px-2'>
							+ {CurrencyFormat(challenge.increasedBid)} USD
						</div>
					}
				</div>
				<div className='flex items-center gap-2'>
					<Icon name='help' className={`text-primary ${dark && 'text-white'}`} size='1.5rem'/>
					<span className='font-semibold mr-1 uppercase italic'>What's this about?</span>
				</div>
				<p className='text-lg'>
					{challenge.description}
				</p>
				<div className='flex items-center gap-2'>
					{
						streamerView && !disabled && (
							accepted || done ?
								<></>
								:
							selected ?
								<Button onClick={onRemove} className='bg-primary text-white grow'>
									<Icon name='cancel' size='1.1rem' className='mr-1'/>
									Remove
								</Button>
								:
								<>
									<Button onClick={onReject} className='border border-primary text-primary grow'>
										<Icon name='close' size='1.1rem' className='mr-1'/>
										Reject
									</Button>
									<Button onClick={onAccept} className='bg-primary text-white grow'>
										<Icon name='thumb_up' size='1.1rem' className='mr-1'/>
										Accept
									</Button>
								</>
						)
					}

					{
						!streamerView && (
							challenge.isPinned ?
								<Button onClick={onIncreaseBid} className='bg-primary text-white grow'>
									<Icon name='chart_data' size='1.1rem' className='mr-1'/>
									Increase the bid!
								</Button>
							:
							(done && inPoll && challenge.poll[userId] === undefined) &&
								<>
									<Button onClick={() => onVote(false)} className='border border-primary text-primary grow'>
										<Icon name='thumb_down' size='1.1rem' className='mr-1'/>
										Failed it!
									</Button>
									<Button onClick={() => onVote(true)} className='bg-primary text-white grow'>
										<Icon name='thumb_up' size='1.1rem' className='mr-1'/>
										Nailed it!
									</Button>
								</>
						)
					}

					{
						done && !dark && (challenge.poll[userId] !== undefined || streamerView || !inPoll) &&
						<div className='flex justify-evenly w-full'>
							<div className='flex items-center'>
								<Icon name='thumb_down' size='1.1rem' className='mr-1 text-primary'/>
								{ approvedPercentage !== null ? (100 - approvedPercentage) : '-' }%
							</div>
							<div className='flex items-center'>
								<Icon name='thumb_up' size='1.1rem' className='mr-1 text-primary'/>
								{ approvedPercentage !== null ? approvedPercentage : '-' }%
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
};

export default Challenge;