import ChallengesList from "../components/challengesList/ChallengesList";
import Logo from "../components/logo/Logo";
import Button from "../components/button/Button";
import TextInput from "../components/text-input/TextInput";
import Timer from "../components/timer/Timer";
import Icon from "../components/icon/Icon";
import axios from "../utils/axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useGlobal } from "./GlobalContext";
import styles from './StreamProvider.module.css';
import ShareStreamButton from "../components/shareStreamButton/ShareStreamButton";

export const StreamContext = createContext({
	streamInfo: {
		id: null,
		name: null,
		description: null,
		preview: false,
		previewDuration: null,
		isScheduled: false,
		scheduledDateTime: null,
		inChallengeMode: false,
		inPoll: false,
		started: false,
		finished: false,

		ownerUsername: null,
	},
	showChat: true,
	messages: [],
	isPaused: false,
	subscribed: false,
	challenges: [],

	keepWatching: () => {},
	fetchChallenges: () => {},

	setChallenges: () => {},
	setCloseStream: () => {},
	setShowChat: () => {},
	setIsPaused: () => {},
	setOnPreviewFinished: () => {},
});

export function useStream() {
	const context = useContext(StreamContext);
	if (context === undefined) {
		throw new Error('useStream must be used within a StreamContext.Provider');
	}
	return context;
}

export function StreamProvider({ streamerView, children }) {
	const { id } = useParams();
	const [streamInfo, setStreamInfo] = useState({
		id: null,
		name: null,
		description: null,
		preview: false,
		previewDuration: null,
		isScheduled: false,
		scheduledDateTime: null,
		inChallengeMode: false,
		inPoll: false,
		started: false,
		finished: false,

		ownerUsername: null,
	});
	const [showChat, setShowChat] = useState(true);
	const [isPaused, setIsPaused] = useState(false);
	const [previewFinished, setPreviewFinished] = useState(false);
	const [chatConnection, setChatConnection] = useState(null);
	const [messages, setMessages] = useState([]);
	const [newMessage, setNewMessage] = useState('');
	const [showChallenges, setShowChallenges] = useState(false);
	const [challenges, setChallenges] = useState([]);
	const [closeStream, setCloseStream] = useState(() => {});
	const [onPreviewFinished, setOnPreviewFinished] = useState(() => {});
	const [subscribed, setSubscribed] = useState(false);
	const { requestLogin, hasAccessToStream, showAlert } = useGlobal();

	const fetchChallenges = async () => {
		try {
			const res = await axios.get(`/challenge/stream/${id}`);
			const challenges = res.data.filter(challenge => challenge.status !== 'pending' && challenge.status !== 'declined');
			setChallenges(challenges);
		} catch (err) {
			showAlert('Error getting challenges', 'error', err);
		}
	};

	const retrieveStreamInfo = async () => {
		try {
			const hasAccess = await hasAccessToStream(id);
			setStreamInfo(prev => ({...prev, preview: !hasAccess}));
			
			const res = await axios.get(`/stream/${id}`);
			setStreamInfo(prev => ({...prev, ...res.data}));
		} catch (err) {
			showAlert('Error getting stream info', 'error', err);
		}
		fetchChallenges();
	};

	useEffect(() => {
		const connectChat = async () => {
			try {
				const res = await axios.get(`/stream/${id}/chatToken`)
				const chatToken = res.data.token;
				const socket = 'wss://edge.ivschat.us-east-1.amazonaws.com';
				const connection = new WebSocket(socket, chatToken);
				connection.onmessage = (event) => {
					receiveMessage(JSON.parse(event.data));
				}
				setChatConnection(connection);
			} catch (err) {
				showAlert('Error getting chat token', 'error', err);
			}
		};
		connectChat();

		const isSubscribed = async () => {
			try {
				const res = await axios.get(`/stream/${id}/subscribed`);
				setSubscribed(res.data.isSubscribed);
			} catch {}
		};
		isSubscribed();

		retrieveStreamInfo();
	}, [id, hasAccessToStream, showAlert]);

	const handlePreviewFinished = () => {
		if(!streamInfo.preview) return;
		setPreviewFinished(true);
		onPreviewFinished();
	};

	const keepWatching = async () => {
		const logged = await requestLogin();
		if(!logged) return;
		const res = await axios.post(`/stream/${id}/access`);
		let url = res.data;
		window.location.href = url;
	};

	const sendMessage = async () => {
		if(!newMessage) return;
		const payload = {
			Action: 'SEND_MESSAGE',
			Content: newMessage,
		};
		if(chatConnection) {
			chatConnection.send(JSON.stringify(payload));
			setNewMessage('');
		}
	};

	const receiveMessage = async (data) => {
		if(data.Type === 'MESSAGE') {
			setMessages(old => [...old, {
				display_name: data.Sender.Attributes.DisplayName,
				message: data.Content,
				timestamp: data.SendTime
			}]);
		}
		else if(data.Type === 'EVENT') {
			switch(data.EventName) {
				case 'stream_start':
					setTimeout(() => {
						setStreamInfo(prev => ({...prev, started: true}));
					}, 5000);
					break;
				case 'stream_stop':
					setStreamInfo(prev => ({...prev, finished: true}));
					break;
				case 'challenge_update':
					try {
						if(data.Attributes.status === 'ready') {
							showAlert('Incoming challenge!', 'challenge');
						}
	
						const res = await axios.get(`/challenge/stream/${id}`);
						const challenges = res.data.filter(challenge => challenge.status !== 'pending' && challenge.status !== 'declined');
						setChallenges(challenges);
					} catch (err) {
						showAlert('Error getting challenges', 'error', err);
					}
					break;
				case 'challenge_mode_start':
					setStreamInfo(prev => ({...prev, inChallengeMode: true}));
					retrieveStreamInfo();
					showAlert('Challenge mode started!', 'success');
					break;
				case 'challenge_mode_stop':
					setStreamInfo(prev => ({...prev, inChallengeMode: false}));
					retrieveStreamInfo();
					showAlert('Challenge mode finished!', 'success');
					break;
				case 'poll_started':
					setStreamInfo(prev => ({...prev, inPoll: true}));
					retrieveStreamInfo();
					showAlert('Poll started!', 'success');
					break;
				case 'poll_finished':
					setStreamInfo(prev => ({...prev, inPoll: false}));
					retrieveStreamInfo();
					showAlert('Poll finished!', 'success');
					break;
				case 'challenge_voted':
					const { challengeId, userId, vote } = data.Attributes;
					setChallenges(old => {
						const ix = old.findIndex(challenge => challenge.id === challengeId);
						if(!old[ix].poll)
							old[ix].poll = {};
						old[ix].poll[userId] = vote === 'true';
						return [...old];
					});
					break;
				case 'challenge_pinned':
					const { challengeId: pinnedChallengeId, isPinned } = data.Attributes;
					setChallenges(old => {
						const ix = old.findIndex(challenge => challenge.id === pinnedChallengeId);
						old[ix].isPinned = isPinned === 'true';
						return [...old];
					});
					break;
				case 'challenge_increased_bid':
					const { challengeId: increasedChallengeId, increasedBid } = data.Attributes;
					setChallenges(old => {
						const ix = old.findIndex(challenge => challenge.id === increasedChallengeId);
						old[ix].increasedBid = Number(increasedBid);
						return [...old];
					});
					break;
			}
		}
	};

	const streamEndedLayout = (
		<div onPo className={`${styles['stream-ended']} z-10`}>
			<Logo dark/>
			<h1 className='text-white text-4xl font-bold'>Your Preview has ended!</h1>
			<Button onClick={keepWatching} className='bg-primary text-white text-xl mt-4'>Keep Watching!</Button>
		</div>
	);

	const streamLayout = (
		<>
			<div className={`${styles['top-right-actions']} z-10`}>
				<Button onClick={closeStream} className='bg-primary text-white' icon>
					<Icon name='close' size='1.2rem'/>
				</Button>
				<ShareStreamButton streamId={id}/>
				{
					streamInfo.started && !streamInfo.finished &&
					<Button onClick={() => setShowChallenges(true)} className='bg-primary text-white' icon>
						<Icon name='directions_run' size='1.2rem'/>
					</Button>
				}
			</div>

			{
				streamInfo.inChallengeMode &&
				<div className="fixed top-3 left-1/2 -translate-x-1/2 bg-white p-2 rounded-xl flex gap-1 items-center z-10">
					<Icon name='workspace_premium' size='1.1rem' className='text-primary'/>
					Challenge Mode!
				</div>
			}

			{
				streamInfo.started && !streamInfo.finished &&
				<div
					className="flex flex-col fixed bottom-1 left-1/2 -translate-x-1/2 pb-1 z-10"
					style={{ width: '100%', maxWidth: '400px' }}
				>
					<div
						className="flex flex-col gap-2 overflow-y-hidden"
						style={{ display: showChat ? 'block' : 'none' }}
					>
						{
							messages.map((message, i) => (
								<div className="flex gap-3 items-center" key={i} style={{ opacity: 1 - (messages.length - i - 1)*.15 }}>
									<div className="rounded-full bg-primary-lighter" style={{ width: '40px', height: '40px' }}>
									</div>
									<div key={i} className='flex flex-col'>
										<span className='text-white'>@{message.display_name}</span>
										<span className='text-white text-lg'>{message.message}</span>
									</div>
								</div>
							))
						}
					</div>
					<div className="flex gap-3" style={{ marginTop: streamerView ? '6.5rem':''}}>
						<TextInput
							value={newMessage} onInput={(e) => { setNewMessage(e.target.value) }}
							contClassName='grow' placeholder='Message...'
							onKeyDown={(e) => { if(e.key === 'Enter') sendMessage() }}
						/>
						<Button onClick={sendMessage} className='bg-primary text-white' icon>
							<Icon name='send' size='1.4rem'/>
						</Button>
					</div>
				</div>
			}

			{
				streamInfo.preview && streamInfo.previewDuration > 0 &&
				<div className={`fixed right-3 bottom-12 z-10`}>
					<Timer isPaused={isPaused} time={streamInfo.previewDuration} onEnd={handlePreviewFinished}/>
				</div>
			}

	 		{ <ChallengesList show={showChallenges} streamId={id} streamerView={streamerView} onClose={() => setShowChallenges(false)}/> }
		</>
	);

	const contextValue = useMemo(() =>({
		streamInfo,
		showChat,
		messages,
		isPaused,
		challenges,
		subscribed,

		keepWatching,
		fetchChallenges,

		setChallenges,
		setCloseStream,
		setShowChat,
		setIsPaused,
		setOnPreviewFinished,
	}), [streamInfo, showChat, messages, isPaused, challenges, subscribed, fetchChallenges, keepWatching, setChallenges, setCloseStream, setShowChat, setIsPaused, setOnPreviewFinished]);

	return (
		<StreamContext.Provider value={contextValue}>
			<div className="bg-[#000] h-full relative flex items-start overflow-hidden">
				{ previewFinished ? streamEndedLayout : streamLayout }
				{children}
			</div>
		</StreamContext.Provider>
	);
}