import Button from "../button/Button";
import Icon from "../icon/Icon";
import { CopyToClipboard } from '../../utils/Text';
import { useGlobal } from "../../contexts/GlobalContext";

const ShareButton = ({ url, title, files, text, className, ...props }) => {
	const { showAlert } = useGlobal();
	const share = async () => {
		try {
			await navigator.share({ url: text ? undefined : url, title, files, text, });
		} catch (error) {
			if(url) {
				CopyToClipboard(url, () => showAlert('Link copied to clipboard!', 'success'));
			}
		}
	};

	return (
		<Button onClick={share} className={`bg-primary text-white ${className}`} {...props} icon>
			<Icon name="share" />
		</Button>
	);
};

export default ShareButton;