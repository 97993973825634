const Dialog = ({ show, onClose, zIndex, width, maxWidth, persistent, children }) => {
	return (
		<div
			onClick={persistent ? null : onClose}
			className={`fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center ${show ? '':'hidden'}`}
			style={{
				zIndex: zIndex || 50,
			}}
		>
			<div
				onClick={e => e.stopPropagation()}
				className="bg-white rounded-lg p-4"
				style={{
					width: width || '95%',
					maxWidth: maxWidth || '600px',
				}}
			>
				{children}
			</div>
		</div>
	);
};

export default Dialog;