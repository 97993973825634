import { useState, useEffect } from "react";

export default function Timer({ time, isPaused, onEnd }) {
	const [currentTime, setCurrentTime] = useState(time);

	useEffect(() => {
		if (currentTime <= 0) {
			onEnd();
			return;
		}

		const timer = setTimeout(() => {
			if(isPaused) return;
			setCurrentTime(currentTime - 1);
		}, 1000);

		return () => clearTimeout(timer);
	}, [currentTime, onEnd]);

	return (
		<div className="text-center">
			<h1 className="text-6xl font-bold text-white">{currentTime}</h1>
		</div>
	);
}