const baseClassName = 'material-symbols-rounded';

function Icon({ size, name, filled, className, style, onClick }) {
	return (
		<span
			onClick={onClick}
			className={`${baseClassName} ${className}`}
			style={{
				fontSize: size || 'inherit',
				fontVariationSettings: filled ? '"FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48' : '',
				...style
			}}
		>
			{ name }
		</span>
	);
}

export default Icon;