import Icon from "../../components/icon/Icon";
import Button from "../../components/button/Button";
import styles from './Stream.module.css';
import { useStream } from "../../contexts/StreamContext";
import { useGlobal } from "../../contexts/GlobalContext";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import IVSBroadcastClient from 'amazon-ivs-web-broadcast';
import { stopStream, getMic } from "../../utils/mediaDevices";
import { useUserMedia } from "../../contexts/UserMediaContext";
import axios from "../../utils/axios";
import logoImage from '../../assets/images/logo/logo_name.svg';
import { useNavigate } from "react-router-dom";

export default function Stream() {
	const { id: streamId } = useParams();
	const { showAlert, requestLogin } = useGlobal();
	const preview = useRef(null);
	const logo = useRef(null);
	const [client, setClient] = useState(null);
	const [connectionStatus, setConnectionStatus] = useState('disconnected');
	const [isStarted, setIsStarted] = useState(false);
	const [isPaused, setIsPaused] = useState(false);
	const [isMuted, setIsMuted] = useState(false);
	const [error, setError] = useState('');
	const { videoStream, toggleVideo } = useUserMedia();
	const navigate = useNavigate();

	const { showChat, setCloseStream, setShowChat } = useStream();
	
	async function startBroadcast() {
		if(isStarted) {
			return resumeBroadcast();
		}

		await axios.post(`/stream/${streamId}/p/start`);

		try {
			const res = await axios.get(`/stream/${streamId}/access`);
			const streamKey = res.data.streamKey;
			const ingestEndpoint = res.data.ingestEndpoint;
			
			client.on('connectionStateChange', (state) => {
				setConnectionStatus(state);
				const statesToReconnect = ['disconnected', 'failed'];
				if(statesToReconnect.includes(state)) {
					client.startBroadcast(streamKey, ingestEndpoint)
						.then(() => {
							showAlert('Reconnected to stream.', 'success');
						})
						.catch((err) => {
							console.error('Broadcast failed to start: ', err);
							setError(err.message);
							alert('Broadcast failed to start: ', err);
						});
				}
			});
			client.startBroadcast(streamKey, ingestEndpoint)
				.then(() => {
					showAlert('Stream started.', 'success');
					setIsPaused(false);
					setIsStarted(true);
				})
				.catch((err) => {
					console.error('Broadcast failed to start: ', err);
					setError(err.message);
					alert('Broadcast failed to start: ', err);
				});
		} catch (err) {
			console.error('Broadcast failed to start: ', err);
			setError(err.message);
			alert('Broadcast failed to start: ', err);
		}
	}

	async function resumeBroadcast() {
		client.enableVideo();
		client.enableAudio();
		client.removeImage('logo');
		setIsPaused(false);
		showAlert('Stream resumed.', 'success');
	}
	
	function pauseBroadcast() {
		client.disableVideo();
		client.disableAudio();
		client.addImageSource(logo.current, 'logo', { index: 0 });
		setIsPaused(true);
		showAlert('Stream paused.', 'success');
	}

	async function toggleMuted() {
		if(isMuted) {
			client.enableAudio();
			setIsMuted(false);
		} else {
			client.disableAudio();
			setIsMuted(true);
		}
	}

	useEffect(() => {
		if(!preview.current) return;
		if(!videoStream) return;

		async function configureBroadcast() {
			if(preview.current?.srcObject) {
				stopStream(preview.current.srcObject);
			}
			const settings = videoStream.getVideoTracks()[0].getSettings();

			const landscape = window.matchMedia("(orientation: landscape)").matches;
			const width = landscape ? Math.max(settings.width, settings.height) : Math.min(settings.width, settings.height);
			const height = landscape ? Math.min(settings.width, settings.height) : Math.max(settings.width, settings.height);

			if(!client) {
				const newClient = IVSBroadcastClient.create({
					streamConfig: {
						maxResolution: { width, height },
						maxBitrate: IVSBroadcastClient.BASIC_LANDSCAPE.maxBitrate,
						maxFramerate: IVSBroadcastClient.BASIC_LANDSCAPE.maxFramerate,
					},
				});
				newClient.addVideoInputDevice(videoStream, 'camera', { index: 0 });
				let micStream = await getMic();
				newClient.addAudioInputDevice(micStream, 'mic', { index: 0 });
				setClient(newClient);
			} else {
				client.config.streamConfig.maxResolution = { width: settings.width, height: settings.height };
				client.removeVideoInputDevice('camera');
				client.removeAudioInputDevice('mic');
				client.addVideoInputDevice(videoStream, 'camera', { index: 0 });
				let micStream = await getMic();
				client.addAudioInputDevice(micStream, 'mic', { index: 0 });
			}
			if(preview.current) {
				preview.current.srcObject = videoStream;
			}
		}
		try {
			configureBroadcast();
		} catch (error) {
			alert('Failed to configure broadcast: ' + error.message);
		}
	}, [videoStream, preview.current]);

	useEffect(() => {
		const endStream = async () => {
			const logged = await requestLogin();
			if(!logged) return;
			await axios.post(`/stream/${streamId}/p/stop`);
			client.removeVideoInputDevice('camera');
			client.removeAudioInputDevice('mic');
			client.stopBroadcast();
			navigate('/panel/new');
		};
		setCloseStream(() => endStream);
	}, [setCloseStream, client, navigate, streamId, requestLogin]);

	return (
		<>
			<div className="fixed top-3 left-2 flex flex-col z-20 gap-5">
				<div className="flex items-center bg-white py-1 px-2 rounded-full gap-1">
					<Icon name={ connectionStatus === 'connected' ? 'smartphone' : 'mobile_off' } size='1.5rem'/>
					<span className="text-primary font-bold text-lg">{ connectionStatus === 'connected' && !isPaused ? 'Live' : 'Not Live' }</span>
					<div className="w-3 h-3 bg-primary rounded-full"></div>
				</div>
				<Button onClick={toggleMuted} icon>
					<Icon name={isMuted ? 'mic_off' : 'mic'} className='text-white' size='2.5rem'/>
				</Button>
			</div>

			{
				error && (
					<div className={'absolute left-1/2 top-1/2 text-white capitalize -translate-x-1/2'}>
						{error}
					</div>
				)
			}

			{
				!error &&
					(
						<>
							<div className="absolute left-1/2 -translate-x-1/2" style={{width: '100dvw'}}>
								<video ref={preview} className="w-full max-h-screen" autoPlay playsInline muted></video>
							</div>
							<div className='hidden text-center grow'>
								<img ref={logo} src={logoImage} alt="Logo"/>
							</div>
						</>
					)
			}

			<div className={`${styles['controls']} z-20`}>
				<button onClick={toggleVideo} className="cursor-pointer">
					<div className="flex flex-col justify-center items-center text-white text-lg gap-1">
						<Icon name='flip_camera_android' size='2rem'/>
						<span className="text-sm">Flip Camera</span>
					</div>
				</button>
				
				{
					isStarted && !isPaused ?
						<button onClick={pauseBroadcast} className="cursor-pointer">
							<div className="flex flex-col items-center text-white italic gap-1">
								<Icon name='stop_circle' size='3.8rem'/>
								<span className="text-lg uppercase">Pause Streaming</span>
							</div>
						</button>
						:
						<button onClick={startBroadcast} className="cursor-pointer">
							<div className="flex flex-col items-center text-white italic gap-1">
								<div className={`${styles['record-btn']}`}>
									<div className={`${styles['dot']} bg-primary`}></div>
								</div>
								<span className="text-white uppercase">Start Streaming</span>
							</div>
						</button>
				}

				<button onClick={() => setShowChat(!showChat)} className="cursor-pointer">
					<div className="flex flex-col justify-center items-center text-white text-lg gap-1">
						<Icon name={ showChat ? 'speaker_notes_off' : 'chat' } size='2rem'/>
						<span className="text-sm">{ showChat ? 'Hide' : 'Show' } Chat</span>
					</div>
				</button>
			</div>
		</>
	);
}