import styles from './TextInput.module.css';
import Icon from '../icon/Icon';

const TextInput = ({
		value, icon, type, placeholder,
		height, name, contClassName, className, readonly, required, disabled,
		onKeyDown, onInput, onClick
	}) => {

	return (
		<div onClick={onClick} className={`${styles['input-container']} ${contClassName}`}>
			{ icon && <Icon name={icon} className={`${styles.icon} text-gray-300`} size='18px' /> }
			<input
				value={value} onInput={onInput} onKeyDown={onKeyDown}
				name={name}
				className={`rounded-lg w-full ${className} ${icon ? 'pl-8' : 'pl-4'} ${disabled ? 'bg-gray-100' : ''}`}
				type={type || 'text'}
				placeholder={placeholder}
				disabled={disabled}
				readOnly={readonly}
				required={required}
				style={{ lineHeight: height || '16px' }}
			/>
		</div>
	);
}
export default TextInput;