import { createContext, useContext, useEffect, useState } from "react";
import { stopStream } from "../utils/mediaDevices";

export const UserMediaContext = createContext({
	videoStream: null,
	videoFacingMode: 'user',
	toggleVideo: () => {},

	audioStream: null,
});

export function useUserMedia() {
	const context = useContext(UserMediaContext);
	if (context === undefined) {
		throw new Error('useUserMedia must be used within a UserMediaContext.Provider');
	}
	return context;
}

export function UserMediaProvider({ children }) {
	const [videoStream, setVideoStream] = useState(null);
	const [audioStream, setAudioStream] = useState(null);
	const [videoFacingMode, setVideoFacingMode] = useState('user');

	
	useEffect(() => {
		async function setStreams() {
			const audioMedia = await navigator.mediaDevices.getUserMedia({ audio: true });
			setAudioStream(audioMedia);
			const videoMedia = await navigator.mediaDevices.getUserMedia({
				video: { facingMode: videoFacingMode },
			});
			setVideoStream(videoMedia);
		}
		setStreams();
		return () => {
			setVideoStream(prev => stopStream(prev));
			setAudioStream(prev => stopStream(prev));
		}
	}, [videoFacingMode]);

	const toggleVideo = async () => {
		const newFacingMode = videoFacingMode === 'user' ? 'environment' : 'user';
		setVideoFacingMode(newFacingMode);
		return newFacingMode;
	};

	return (
		<UserMediaContext.Provider value={{ videoStream, videoFacingMode, toggleVideo, audioStream }}>
			{children}
		</UserMediaContext.Provider>
	);
}