import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home/Home';
import StreamsPanel from './pages/streamsPanel/StreamsPanel';
import Profile from './pages/profile/Profile';
import Stream from './pages/stream/Stream';
import StreamWatch from './pages/streamWatch/StreamWatch';
import Alert from './components/alert/Alert';
import Authentication from './components/authentication/Authentication';
import LoadingScreen from './components/loadingScreen/LoadingScreen';
import { useEffect, useMemo, useState } from 'react';
import { useAuth } from './hooks/Authentication';
import { GlobalContext } from './contexts/GlobalContext';
import { StreamProvider } from "./contexts/StreamContext";
import { UserMediaProvider } from "./contexts/UserMediaContext";
import ConfirmDialog from './dialogs/confirmDialog/ConfirmDialog';
import { useConfirmDialog } from './hooks/useConfirmDialog';
import axios from './utils/axios';

function App() {
  const [loadingCount, setLoadingCount] = useState(0);
  const [alertInfo, setAlertInfo] = useState({ show: false, message: '', type: 'success' });
  const [alertTimeout, setAlertTimeout] = useState(null);
  const _showAlert = (message, type, error = null) => {
    if (error) console.error(error);
    setAlertInfo({ show: true, message, type });
    clearTimeout(alertTimeout);
    setAlertTimeout(
      setTimeout(() => setAlertInfo({ show: false, message, type }), 5000)
    );
  };
  const addLoading = (delta = 1) => {
    setLoadingCount(prev => prev + delta);
  };

  useEffect(() => {
    axios.interceptors.request.use(
      async (config) => {
        addLoading();
        return config;
      },
      (error) => {
        addLoading();
        Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      (response) => {
        addLoading(-1);
        return response;
      },
      (error) => {
        addLoading(-1);
        return Promise.reject(error);
      }
    );
  }, []);

  const showAlert = useMemo(() => _showAlert, []);
  const _useAuth = useAuth();
  const _useConfirmDialog = useConfirmDialog();

  return (
    <GlobalContext.Provider value={{ showAlert, ..._useAuth, showConfirmDialog: _useConfirmDialog.open }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}></Route>
          <Route path="/panel/:tab" element={<StreamsPanel/>}></Route>
          <Route path="/profile" element={<Profile/>}></Route>
          <Route path="/stream/:id" element={
            <UserMediaProvider>
              <StreamProvider streamerView>
                <Stream/>
              </StreamProvider>
            </UserMediaProvider>
          }></Route>
          <Route path="/watch/:id" element={
            <StreamProvider>
              <StreamWatch/>
            </StreamProvider>
          }></Route>
        </Routes>
        <Alert
          show={alertInfo.show}
          message={alertInfo.message}
          type={alertInfo.type}
        />
        { loadingCount > 0 && <LoadingScreen/> }
        {
          _useAuth.requestingLogin && <Authentication onLogged={_useAuth.resolveLogin} onClose={_useAuth.rejectLogin}/>
        }
        {
          _useConfirmDialog.show &&
          <ConfirmDialog
            show={_useConfirmDialog.show}
            title={_useConfirmDialog.info.title}
            icon={_useConfirmDialog.info.icon}
            cancelButtonText={_useConfirmDialog.info.cancelButtonText}
            confirmButtonText={_useConfirmDialog.info.confirmButtonText}
            cancelButtonIcon={_useConfirmDialog.info.cancelButtonIcon}
            confirmButtonIcon={_useConfirmDialog.info.confirmButtonIcon}
            message={_useConfirmDialog.info.message}
            onConfirm={_useConfirmDialog.resolve}
            onCancel={_useConfirmDialog.reject}
          />
        }
      </BrowserRouter>
    </GlobalContext.Provider>
  );
}

export default App;
