import { useEffect, useMemo, useState } from 'react';
import axios from '../utils/axios';

export const useAuth = () => {
	const [user, setUser] = useState(null);
	const [requestingLogin, setRequestingLogin] = useState(false);
	const [resolveLogin, setResolveLogin] = useState(null);
	const [rejectLogin, setRejectLogin] = useState(null);

	const _requestLogin = () => {
		return new Promise(async resolve => {
			const user = await _getCurrentUser();
			if (user && user.email && user.phone) {
				resolve(true);
				return;
			}
	
			setRequestingLogin(true);
			setResolveLogin(() => {
				return () => {
					setRequestingLogin(false);
					resolve(true);
				}
			});
			setRejectLogin(() => {
				return () => {
					setRequestingLogin(false);
					resolve(false);
				}
			});
		});
	};
	
	const _getCurrentUser = async () => {
		try {
			const res = await axios.get('/user/current');
			return res.data;
		} catch (err) {
			return null;
		}
	};
	
	const _ensureLoggedIn = async () => {
		const user = await _getCurrentUser();
		if (!user) {
			window.location.href = '/';
		}
	};
	
	const _hasAccessToStream = async (streamId) => {
		try {
			await axios.get(`user/stream/${streamId}`);
			return true;
		} catch (err) {
			return false;
		}
	};

	const requestLogin = useMemo(() => _requestLogin, []);
	const getCurrentUser = useMemo(() => _getCurrentUser, []);
	const ensureLoggedIn = useMemo(() => _ensureLoggedIn, []);
	const hasAccessToStream = useMemo(() => _hasAccessToStream, []);

	useEffect(() => {
		getCurrentUser().then(user => setUser(user));
	}, [getCurrentUser]);

	return {
		user, setUser,
		requestingLogin, resolveLogin, rejectLogin, requestLogin,
		getCurrentUser, ensureLoggedIn,
		hasAccessToStream,
	};
}