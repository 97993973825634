import { useEffect, useState } from "react";
import { useGlobal } from "../../contexts/GlobalContext";
import Tabs from "../../components/tabs/Tabs";
import CreateStream from "../../components/createStream/CreateStream";
import ScheduledStreams from "../../components/scheduledStreams/ScheduledStreams";
import MainPanel from "../../components/mainPanel/MainPanel";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const StreamsPanel = () => {
	const { getCurrentUser } = useGlobal();
	const { tab } = useParams();
	const [_tab, setTab] = useState(tab || 'new');
	const navigate = useNavigate();
	const [user, setUser] = useState(null);

	useEffect(() => {
		setTab(tab || 'new');
	}, [tab]);

	useEffect(() => {
		getCurrentUser().then(user => setUser(user));
	}, []);
	
	const tabChanged = (tab) => {
		setTab(tab);
		navigate(`/panel/${tab}`);
	};

	return (
		<MainPanel onPrev={() => navigate('/')}>
			<Tabs
				value={_tab}
				onChange={tabChanged}
				className='-mx-2'
				items={[
					{ name: 'A New LifeSTRM', icon: 'videocam', value: 'new', element: <CreateStream/> },
					{ name: 'My LifeSTRM', icon: 'video_camera_front', value: 'my-streams', disabled: !user, element: <ScheduledStreams/> },
				]}
			/>
		</MainPanel>
	);
};

export default StreamsPanel;