import { createContext, useContext } from "react";

export const GlobalContext = createContext({
	showAlert: (message, type, error = null) => {},
	showConfirmDialog: async ({title, message, confirmButtonText, cancelButtonText, icon, cancelButtonIcon, confirmButtonIcon}) => {},

	user: null,
	requestLogin: async () => {},
	getCurrentUser: async () => {},
	ensureLoggedIn: async () => {},

	hasAccessToStream: async () => {},
});

export function useGlobal() {
	const context = useContext(GlobalContext);
	if (context === undefined) {
		throw new Error('useGlobal must be used within a GlobalContext.Provider');
	}
	return context;
}