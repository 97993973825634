import { useEffect, useState } from "react";
import { useGlobal } from "../../contexts/GlobalContext";
import { GetStreamOwnerUrl } from "../../utils/Text";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import axios from '../../utils/axios';
import Icon from "../icon/Icon";
import Button from "../button/Button";
import ShareStreamButton from "../shareStreamButton/ShareStreamButton";

const ScheduledStreams = () => {
	const [streams, setStreams] = useState([]);
	const [liveStream, setLiveStream] = useState(null);
	const { showAlert } = useGlobal();
	const navigate = useNavigate();

	useEffect(() => {
		axios.get('/stream/scheduled')
			.then(res => {
				const _streams = res.data.streams;
				setStreams(_streams);

				_streams.forEach(stream => {
					if (
						moment.utc(stream.scheduledDateTime).isBetween(moment().subtract(10, 'minutes'), moment())
					) {
						setLiveStream(stream);
					}
				});
			})
			.catch(err => showAlert('Error retrieving scheduled streams. Please try again later.', 'error', err));
	}, [showAlert]);

	const goToStream = () => {
		if (!liveStream) return;
		navigate(`/stream/${liveStream.id}`);
	};

	const numberOfDigits = (num) => {
		return num.toString().length;
	};

	return (
		<div className="flex flex-col w-full h-full gap-5">
			{streams.length === 0 && <p className="mt-2">No scheduled streams</p>}

			{
				liveStream && (
					<div>
						<h1 className="text-lg font-bold italic uppercase mb-3">Streaming Live</h1>
						<div onClick={goToStream} className="cursor-pointer">
							<h3 className="font-bold">{ liveStream.name }</h3>
							<span className="text-sm">Start your Streaming!</span>
							<div className="gap-1 items-center" style={{ display: 'grid', gridTemplateColumns: '1fr 30px 35px' }}>
								<div className="flex gap-1 pb-1 items-center border-b-2 border-primary grow overflow-hidden">
									<Icon name='videocam' className='text-primary' size='1.2rem'/>
									<span className="font-semibold overflow-hidden text-ellipsis text-sm">{GetStreamOwnerUrl(liveStream.id)}</span>
								</div>
								<Button icon className='bg-primary text-white' stop={false} dense>
									<Icon name='rocket_launch'/>
								</Button>
								<div className="flex items-center justify-evenly" style={{fontSize: `${1.5 - numberOfDigits(liveStream.accesses.length)*.15}rem`}}>
									<Icon name='local_activity'></Icon>
									{liveStream.accesses.length}
								</div>
							</div>
						</div>
					</div>
				)
			}

			{
				streams.filter(x => x.id != liveStream?.id).length > 0 && 
				<div>
					<h1 className="text-lg font-bold italic uppercase mb-3">Scheduled Streamings</h1>
					{
						streams
							.filter(stream => stream.scheduledDateTime > moment().subtract(10, 'minutes').valueOf() && stream.id !== liveStream?.id)
							.map(stream => (
							<div key={stream.id}>
								<h3 className="font-bold">{ stream.name }</h3>
								<span className="text-sm">Scheduled for {moment(stream.scheduledDateTime).format('DD/MMM/YYYY hh:mm A')}:</span>
								<div className="gap-1 items-center" style={{ display: 'grid', gridTemplateColumns: '1fr 30px 35px' }}>
									<div className="flex gap-1 pb-1 items-center border-b-2 border-primary grow overflow-hidden">
										<Icon name='videocam' className='text-primary' size='1.2rem'/>
										<span className="font-semibold overflow-hidden text-ellipsis text-sm">{GetStreamOwnerUrl(stream.id)}</span>
									</div>
									<ShareStreamButton streamId={stream.id} dense/>
									<div className="flex items-center justify-evenly" style={{fontSize: `${1.5 - numberOfDigits(stream.accesses.length)*.15}rem`}}>
										<Icon name='local_activity'></Icon>
										{stream.accesses.length}
									</div>
								</div>
							</div>
						))
					}
				</div>
			}
		</div>
	);
};

export default ScheduledStreams;