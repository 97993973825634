import Dialog from "../dialog/Dialog";
import Button from "../../components/button/Button";
import Icon from "../../components/icon/Icon";

const ConfirmDialog = 
	({
		show,
		onCancel, onConfirm,
		title, message, icon,
		cancelButtonText, confirmButtonText,
		cancelButtonIcon, confirmButtonIcon,
	}) => {
	return (
		<Dialog show={show} onClose={onCancel} zIndex={50}>
			<div className="flex flex-col items-center gap-3">
				<Icon name={icon || 'check_circle'} className='text-primary' size='3rem'/>
				<span className="text-2xl font-bold italic">{title}</span>
				<hr className="border border-black w-full"/>
				<span className="text-lg">{message}</span>
				<div className="flex gap-3 w-full mt-5">
					<Button onClick={onCancel} className="border border-primary text-primary grow">
						<Icon name={cancelButtonIcon} size='1.2rem'/>
						{cancelButtonText || 'Cancel'}
					</Button>
					<Button onClick={onConfirm} className="bg-primary text-white grow">
						<Icon name={confirmButtonIcon} size='1.2rem'/>
						{confirmButtonText || 'Confirm'}
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

export default ConfirmDialog;