export function CopyToClipboard(text, showAlert, alertMessage) {
	navigator.clipboard.writeText(text);

	if(showAlert) {
		showAlert(alertMessage || 'Copied to clipboard!', 'success');
	}
}

export function GetStreamUrl(id) {
	return `${window.location.origin}/watch/${id}`;
}

export function GetStreamOwnerUrl(id) {
	return `${window.location.origin}/stream/${id}`;
}

export function CopyStreamUrl(id, showAlert) {
	CopyToClipboard(GetStreamUrl(id), showAlert, 'Your link has been copied!');
}

export function CurrencyFormat(amount, precise = false) {
	if(!amount) amount = 0
	const opts = {
		style: 'currency',
		currency: 'USD',
	}
	if(precise){
		opts.minimumFractionDigits = 0
		opts.maximumFractionDigits = 20
	}

	let formatter = new Intl.NumberFormat('en-US', opts)
	return formatter.format(amount)
}

export const ZeroPad = (num, places) => String(num).padStart(places, '0');