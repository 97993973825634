import styles from './CreateStream.module.css';
import TextInput from "../text-input/TextInput";
import Button from '../button/Button';
import axios from '../../utils/axios';
import DatePicker from "../datePicker/DatePicker";
import TimePicker from '../timePicker/TimePicker';
import moment from 'moment';
import { GetStreamUrl } from '../../utils/Text';
import { UpdateObject } from '../../utils/Form';
import { useEffect, useState } from 'react';
import { useGlobal } from '../../contexts/GlobalContext';
import { useNavigate } from "react-router-dom";
import ShareStreamButton from '../shareStreamButton/ShareStreamButton';

function CreateStream() {
	const { showAlert } = useGlobal();
	const [user, setUser] = useState(null);
	const [streamInfo, setStreamInfo] = useState({
		name: '',
		streamName: '',
		description: '',
		rate: '',
		previewDuration: 30,

		scheduled: false,
		scheduledDate: moment().format('YYYY-MM-DD'),
		scheduledTime: '1:00 AM',
	});
	const navigate = useNavigate();
	const { name, streamName, description, scheduled, rate, previewDuration, scheduledDate, scheduledTime } = streamInfo;
	const handleChange = e => UpdateObject(e, setStreamInfo);
	const handleDescriptionChange = e => {
		let _description = e.target.value;
		_description = _description.slice(0, 400);
		setStreamInfo(prev => ({ ...prev, description: _description }));
	};

	const views = ['form', 'review'];
	const [view, setView] = useState(views[0]);
	const reviewSpanClasses = 'text-xl font-bold';
	const isInFormView = view === views[0];
	const isInReviewView = view === views[1];
	const [streamId, setStreamId] = useState('');
	const [streamUrl, setStreamUrl] = useState('');

	const { getCurrentUser } = useGlobal();
	useEffect(() => {
		getCurrentUser().then(setUser);
	}, []);

	function goToReview(e) {
		const form = document.getElementById('form');
		if (!form.reportValidity()) return;

		if(scheduled && !scheduledTime) {
			showAlert('Invalid time format.', 'error');
			return;
		}

		if(scheduled) {
			const scheduledDateTime = moment(scheduledDate + ' ' + scheduledTime, 'YYYY-MM-DD hh:mm A').valueOf();
			if(scheduledDateTime < moment().valueOf()) {
				showAlert('Scheduled date and time cannot be in the past.', 'error');
				return;
			}
		}

		axios.get('/stream/newId')
			.then(res => {
				setStreamId(res.data.id);
				setStreamUrl(GetStreamUrl(res.data.id));
				setView(views[1]);
			})
			.catch(err => showAlert('Error retrieving stream information. Please try again later.', 'error', err));
	};

	async function goToStream(e) {
		if(!user) {
			const userRes = await axios.post('/user/basic', { username: name });
			localStorage.setItem('token', userRes.data.token);
			const _user = await getCurrentUser();
			setUser(_user);
		}

		try {
			const scheduledDateTime = moment(scheduledDate + ' ' + scheduledTime, 'YYYY-MM-DD hh:mm A').valueOf();
			await axios.post('/stream', {...streamInfo, id: streamId, scheduledDateTime})
			if(scheduled)
				navigate('/panel/my-streams');
			else
				navigate('/stream/' + streamId);
		} catch (error) {
			showAlert('Error creating stream. Please try again later.', 'error', error)
		}
	}

	const goToLogin = () => navigate('/');

	return (
		<div className='flex justify-center px-5'>
			<form onSubmit={isInFormView ? goToReview : goToStream} id='form' className={`${styles.form} flex flex-col gap-6`}>
				<label>
					Who are you? <br/>
					{
						(isInFormView && !user) ?
							<TextInput value={name} onInput={handleChange} required placeholder='Your name here' icon='account_circle' name='name'/> :
							<span className={reviewSpanClasses}>@{streamInfo.name || user.username}</span>
					}
				</label>
				<label>
					Name of the Stream <br/>
					{
						isInFormView ?
							<TextInput value={streamName} onInput={handleChange} required placeholder='Type here' name='streamName'/> :
							<span className={reviewSpanClasses}>{streamInfo.streamName}</span>
					}
				</label>
				<label>
					<span className='uppercase italic font-semibold'>What's this about?</span> <br/>
					{
						isInFormView ?
							<>
								<textarea value={description} onInput={handleDescriptionChange} className='rounded-lg w-full' rows='3' placeholder='Type a description here...' name='description'/>
								<div className={`text-right text-xs ${description.length >= 400 ? 'text-primary' : 'text-gray-800'}`}>
									{description.length}/400
								</div>
							</>
							:
							<span className={reviewSpanClasses}>{streamInfo.description || '-'}</span>
					}
				</label>
				{
					(isInFormView && user) &&
					<label>
						<input checked={scheduled} onChange={handleChange} type='checkbox' className='mr-2' name='scheduled'/> Schedule your stream
					</label>
				}

				{
					scheduled &&
					<div className='flex gap-5'>
						<label className='grow'>
							When?<br/>
							{
								isInFormView ?
									<DatePicker
										value={scheduledDate} onChange={date => setStreamInfo(prev => ({ ...prev, scheduledDate: date }))}
									/>
									:
									<span className={reviewSpanClasses}>{moment(streamInfo.scheduledDate).format('MMM D, YYYY')}</span>
							}
						</label>
						<label className='grow'>
							What time? <br/>
							{
								isInFormView ?
									<TimePicker
										value={scheduledTime} onChange={time => setStreamInfo(prev => ({ ...prev, scheduledTime: time }))}
									/>
									:
									<span className={reviewSpanClasses}>{streamInfo.scheduledTime}</span>
							}
						</label>
					</div>
				}

				<div className='flex gap-5'>
					<label className='grow'>
						Rate per user <br/>
						{
							isInFormView ?
								<TextInput value={rate} onInput={handleChange} required type='number' placeholder='$ 0.00 USD' name='rate'/> :
								<span className={reviewSpanClasses}>${streamInfo.rate} USD</span>
						}
					</label>
					<label className='grow'>
						Preview Duration {isInFormView ? ' (sec)' : ''}<br/>
						{
							isInFormView ?
								<TextInput value={previewDuration} onInput={handleChange} required type='number' placeholder='30' name='previewDuration'/> :
								<span className={reviewSpanClasses}>{streamInfo.previewDuration}s</span>
						}
					</label>
				</div>

				{
					isInReviewView &&
					<div className='relative'>
						<div className={`${styles['details-border']} border border-primary`}></div>
						<div className='mt-7'>
							<h3 className='text-xl font-bold'>Stream details</h3>
							<span className='text-gray-600'>Share the link to let people know!</span>

							<div className='flex flex-col gap-3 mt-3'>
								<div>
									Share link:
									<div className='flex items-center gap-2'>
										<TextInput contClassName='grow' className='bg-gray-100 text-gray-700 text-sm' value={streamUrl} readonly/>
										<ShareStreamButton streamId={streamId}/>
									</div>
								</div>
							</div>
						</div>
					</div>
				}

				<div className={`${!isInFormView && 'hidden'} flex gap-5`}>
					<Button onClick={goToLogin} className='border-solid border border-primary text-primary w-1/2'>Cancel</Button>
					<Button onClick={goToReview} className='bg-primary text-white w-1/2' type='submit'>Create now!</Button>
				</div>
				<div className={`${isInFormView && 'hidden'} flex gap-5`}>
					<Button onClick={() => setView(views[0])} className='border-solid border border-primary text-primary grow'>Go Back</Button>
					<Button onClick={goToStream} className='bg-primary text-white grow'>Looks Good!</Button>
				</div>
			</form>
		</div>
	);
}

export default CreateStream;