import Dialog from "../dialog/Dialog";
import Button from "../../components/button/Button";
import Icon from "../../components/icon/Icon";
import TextInput from '../../components/text-input/TextInput';
import axios from "../../utils/axios";
import { useEffect, useRef, useState } from "react";
import { useStream } from "../../contexts/StreamContext";
import { useGlobal } from "../../contexts/GlobalContext";

const IncreaseBidDialog = ({ show, challengeId, onClose }) => {
	const [increment, setIncrement] = useState('');
	const form = useRef(null);
	const { streamInfo } = useStream();
	const { requestLogin } = useGlobal();

	useEffect(() => {
		setIncrement('');
	}, [show]);

	const send = async (e) => {
		e?.preventDefault();
		if(!form.current.reportValidity()) return;

		const logged = await requestLogin();
		if(!logged) return;

		const _increment = Number(increment);
		if(isNaN(_increment)) {
			alert('You must enter a valid number');
			return;
		}

		if(increment < 1) {
			alert('You must increase the bid by at least $1 USD');
			return;
		}

		const res = await axios.post(`/challenge/${challengeId}/increase-bid`, {
			amount: _increment,
		});

		const url = res.data;
		window.location.href = url;
	};

	return (
		<Dialog show={show} onClose={onClose} zIndex={50} maxWidth='400px' persistent>
			<div className="flex flex-col gap-7">
				<div className="flex items-center gap-3 border-b border-secondary pb-1">
					<Icon name='chart_data' className='text-primary' size='3rem'/>
					<div>
						<span className="text-2xl font-bold">Increase the Bid!</span>
						<br />
						<span className="text-secondary-light">Make it more interesting!</span>
					</div>
				</div>
				<form ref={form} className="flex flex-col gap-7">
					<label>
						<span className='font-semibold uppercase italic'>How much you want to increase?</span>
						<TextInput
							value={increment} onInput={(e) => setIncrement(e.target.value)}
							placeholder='$0.00 USD' className='w-32'
							type='number' required
						/>
					</label>

					<div className="flex gap-3">
						<Button onClick={onClose} className='border border-primary text-primary grow'>Cancel</Button>
						<Button onClick={send} className='bg-primary text-white grow'>Increase!</Button>
					</div>
				</form>
			</div>
		</Dialog>
	);
};

export default IncreaseBidDialog;