import logo from '../../assets/images/logo/logo_name.svg';
import logoLight from '../../assets/images/logo/logo_name_light.svg';
import logoDark from '../../assets/images/logo/logo_name_dark.svg';

const logoByMode = {
	default: logo,
	light: logoLight,
	dark: logoDark
};

function Logo({size, small, mode}) {
	if(!mode) mode = 'default';
	return (
		<div className="logo inline-flex gap-2">
			<img
				src={ logoByMode[mode] }
				alt="Logo" width={size}
			/>
		</div>
	);
}

export default Logo;