import styles from './MainPanel.module.css';
import Logo from "../../components/logo/Logo";
import Button from "../../components/button/Button";
import Icon from "../../components/icon/Icon";
import { useNavigate, Link } from "react-router-dom";
import { useGlobal } from "../../contexts/GlobalContext";
import { useEffect, useState } from 'react';

const MainPanel = ({ onPrev, children }) => {
	const navigate = useNavigate();
	const { getCurrentUser } = useGlobal();
	const [user, setUser] = useState(null);

	useEffect(() => {
		getCurrentUser().then(user => {
			setUser(user);
		});
	}, []);

	return (
		<div className="p-5 flex justify-center">
			<div style={{ width: '100%', maxWidth: '600px' }}>
				<div className={`${styles.header}`}>
					<div className={styles.gradient}></div>
				</div>
				<div className='relative'>
					<div className="flex justify-between items-center mb-8">
						<Button onClick={() => onPrev ? onPrev() : navigate(-1)} icon>
							<Icon name='chevron_left' size='3rem'/>
						</Button>
						<Logo mode='light' size='150px'/>
						<Button onClick={() => navigate('/profile')} icon className='bg-primary -mb-3' prevent={false} disabled={!user}>
							<Icon name='person' size='1.3rem' className='text-white'/>
						</Button>
					</div>

					{ children }
				</div>
			</div>
		</div>
	);
};

export default MainPanel;