export function stopStream(stream) {
	stream.getTracks().forEach((track) => track.stop());
}

export async function getMic(deviceId) {
	let media;
	const audioConstraints = {
		deviceId: deviceId ? { exact: deviceId } : null,
	};
	media = await navigator.mediaDevices.getUserMedia({
		video: false,
		audio: audioConstraints,
	});
	return media;
}