import styles from './Authentication.module.css';
import TextInput from "../text-input/TextInput";
import Tabs from "../tabs/Tabs";
import Logo from "../logo/Logo";
import Icon from "../icon/Icon";
import PhoneInput from "../phoneInput/PhoneInput";
import Button from '../button/Button';
import axios from '../../utils/axios';
import { useEffect, useRef, useState } from 'react';
import { UpdateObject } from '../../utils/Form';
import { useGlobal } from '../../contexts/GlobalContext';
import MainPanel from '../mainPanel/MainPanel';

const Login = ({ view = 'login', onLogged, onClose, onQuickStart }) => {
	const { showAlert, getCurrentUser } = useGlobal();
	const [_view, setView] = useState(view);
	const [completeOnly, setCompleteOnly] = useState(false);
	const signUpForm = useRef(null);

	useEffect(() => {
		setView(view);
	}, [view]);

	const [authInfo, setAuthInfo] = useState({
		username: '',
		email: '',
		phone: '',
		password: '',
		confirmPassword: '',
	});
	const [disabledFields, setDisabledFields] = useState({
		username: false,
		email: false,
		phone: false,
	});
	const [loginInfo, setLoginInfo] = useState({
		email: '',
		password: '',
	});

	useEffect(() => {
		getCurrentUser().then(user => {
			if (user && user.email && user.phone) onLogged();
			else if (user) {
				setDisabledFields(prev => ({...prev, username: true}));
				if(user.email) {
					setCompleteOnly(true);
					setDisabledFields(prev => ({...prev, email: true}));
				}
				setView('signUp');
				setAuthInfo({
					username: user.username,
					email: user.email || '',
					phone: '',
					password: '',
					confirmPassword: '',
				});
			}
		});
	}, []);

	const login = () => {
		axios.post('/user/login', loginInfo)
			.then(res => {
				localStorage.setItem('token', res.data.token);
				showAlert('Logged in successfully', 'success');
				onLogged();
			})
			.catch(err => {
				console.error(err);
				showAlert('Login failed', 'error');
			});
	};

	const signUp = () => {
		if(!signUpForm.current.reportValidity()) return;
		if(!completeOnly) {
			if (authInfo.password !== authInfo.confirmPassword) {
				showAlert('Passwords do not match', 'error');
				return;
			}
			if (authInfo.password.length < 8) {
				showAlert('Password must be at least 8 characters', 'error');
				return;
			}
			if (!authInfo.password.match(/[a-z]/)) {
				showAlert('Password must contain at least 1 lowercase letter', 'error');
				return;
			}
			if (!authInfo.password.match(/[A-Z]/)) {
				showAlert('Password must contain at least 1 uppercase letter', 'error');
				return;
			}
			if (!authInfo.password.match(/[0-9]/)) {
				showAlert('Password must contain at least 1 number', 'error');
				return;
			}
		}

		axios.post('/user', authInfo)
			.then(res => {
				localStorage.setItem('token', res.data.token);
				showAlert(completeOnly ? 'Changes updated' : 'Account created successfully', 'success');
				onLogged();
			})
			.catch(err => {
				console.error(err);
				showAlert(err.response.data.error || 'Account creation failed', 'error');
			});
	};

	const loginLayout = (
		<>
			<div className="flex mb-5">
				<div className="ml-2">
					<h1 className="text-2xl font-bold">Welcome back!</h1>
					<p className="text-gray-500">Share, stream, earn.</p>
				</div>
			</div>

			<form onSubmit={login} className='flex flex-col gap-3 mb-5'>
				<label>
					<span className="uppercase font-semibold italic">Email</span> <br />
					<TextInput
						onInput={(e) => UpdateObject(e, setLoginInfo)} value={loginInfo.email}
						name='email' placeholder='Type your email here'
					/>
				</label>
				<label>
					<span className="uppercase font-semibold italic">Password</span> <br />
					<TextInput
						onInput={(e) => UpdateObject(e, setLoginInfo)} value={loginInfo.password}
						name='password' type='password' placeholder='Type your your'
					/>
				</label>
				<Button onClick={login} className='bg-primary text-white mx-16 mt-3'>Login</Button>
				<hr className="border mx-24 mt-5"/>
			</form>

			<div className='flex flex-col items-center'>
				<span className='text-lg text-gray-600'>New here - register now in 20 seconds*</span> <br />
				<Button onClick={() => setView('signUp')} className='border border-primary text-primary mt-1 w-3/4'>
					Sign up!
				</Button>
			</div>

			<div className='text-center mt-5'>
				<span className='text-gray-500'>*Register now and obtain all the benefits, like scheduling multiple streams.</span>
			</div>
		</>
	);

	const signUpLayout = (
		<>
			<div className="flex mb-5">
				<div className="ml-2">
					<h1 className="text-2xl font-bold">{completeOnly ? 'Complete your profile!' : 'Welcome!'}</h1>
					<p className="text-gray-500">Share, stream, earn.</p>
				</div>
			</div>
			<form ref={signUpForm} onSubmit={signUp} className='flex flex-col gap-3 mb-5'>
				<label>
					<span className="uppercase font-semibold italic">Username</span> <br />
					<TextInput
						onInput={(e) => UpdateObject(e, setAuthInfo)} value={authInfo.username}
						name='username' placeholder='Type your username here' disabled={disabledFields.username}
						required
					/>
				</label>
				<label>
					<span className="uppercase font-semibold italic">Email</span> <br />
					<TextInput
						onInput={(e) => UpdateObject(e, setAuthInfo)} value={authInfo.email}
						name='email' placeholder='Type your email here' disabled={disabledFields.email}
						required
					/>
				</label>
				<label>
					<span className="uppercase font-semibold italic">Phone</span> <br />
					<PhoneInput
						onInput={(e) => setAuthInfo(prev => ({...prev, phone: e}))} value={authInfo.phone}
						name='phone' placeholder='Type your phone here' disabled={disabledFields.phone} required
					/>
				</label>
				{
					!completeOnly && 
					<>
						<label>
							<span className="uppercase font-semibold italic">Password</span> <br />
							<TextInput
								onInput={(e) => UpdateObject(e, setAuthInfo)} value={authInfo.password}
								name='password' type='password' placeholder='Type your your' required
							/>
						</label>
						<div className='text-gray-600'>
							Your password must contain:
							<ul className='list-disc pl-5'>
								<li>At least 8 characters</li>
								<li>At least 1 (one) uppercase letter</li>
								<li>At least 1 (one) lowercase letter</li>
								<li>At least 1 (one) number</li>
							</ul>
						</div>
						<label>
							<span className="uppercase font-semibold italic">Confirm Your Password</span> <br />
							<TextInput
								onInput={(e) => UpdateObject(e, setAuthInfo)} value={authInfo.confirmPassword}
								name='confirmPassword' type='password' placeholder='Type your password again' required
							/>
						</label>
					</>
				}
				<Button onClick={signUp} className='bg-primary text-white mx-16 mt-3'>{completeOnly ? 'Save Changes!' : 'Sign up!'}</Button>
				<hr className="border mx-24 mt-5"/>
			</form>

			{
				onQuickStart &&
				<>
					<div className='flex flex-col items-center'>
						<span className='text-lg text-gray-600'>You're in a hurry? Try a quick start!*</span> <br />
						<Button onClick={onQuickStart} className='border border-primary text-primary mt-3 w-3/4'>
							Quick Start
						</Button>
					</div>

					<div className='text-center mt-5'>
						<span className='text-gray-500'>*We strongly recommend creating an account to get all the benefits.</span>
					</div>
				</>
			}
		</>
	);

	return (
		<div className={styles.container}>
			<MainPanel onPrev={onClose}>
				{ _view === 'login' ? loginLayout : signUpLayout }
			</MainPanel>
		</div>
	);
}
export default Login;