import Icon from "../icon/Icon";
import { useEffect, useState } from "react";
import styles from './Tabs.module.css';

/*
	items: [
		{
			name: 'Name',
			icon: 'IconName',
			value: 0,
			disabled: false,
			element: <Component/>
		}
	]
*/

const Tabs = ({ className, items, value, onChange = () => {} }) => {
	const [_value, setValue] = useState(value || items[0].value || 0);

	useEffect(() => {
		onChange(_value || items[0].value || 0);
	}, [_value]);

	useEffect(() => {
		setValue(value || items[0].value || 0);
	}, [value]);

	const handleChange = (v) => {
		setValue(v);
		onChange(v);
	};

	const getActive = () => {
		return items.find((item, ix) => (item.value || ix) === _value) || items[0];
	};

	return (
		<div className={className}>
			<div className="flex border-b border-primary">
				{items.map((item, index) => (
					<div
						onClick={() => !item.disabled && handleChange(item.value || index)} key={item.value || index}
						className={`
							${(_value === (item.value || index) && styles['item-active'])}
							${ item.disabled && styles['item-disabled'] }
							${ styles.item }
						`}
					>
						<Icon className={styles.icon} name={item.icon}/>
						<span>{item.name}</span>
					</div>
				))}
			</div>
			<div className="pt-3 p-2">
				{getActive().element}
			</div>
		</div>
	);
};

export default Tabs;