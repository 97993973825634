import styles from './Home.module.css';
import { Link } from "react-router-dom";
import Logo from '../../components/logo/Logo';
import Icon from '../../components/icon/Icon';
import Button from '../../components/button/Button';
import Authentication from '../../components/authentication/Authentication';
import { useEffect, useState } from 'react';
import { useGlobal } from '../../contexts/GlobalContext';

function Home() {
  const [authView, setAuthView] = useState('');
  const [user, setUser] = useState(null);
  const { getCurrentUser } = useGlobal();

  const onLogged = () => {
    window.location.href = '/panel/new';
  };

  useEffect(() => {
    getCurrentUser()
      .then((user) => {
        if (user) {
          setUser(user);
        }
      });
  }, []);

  return (
    <div className={`${styles.home} min-h-full`}>
      <div className={styles.gradient}></div>
      <div className={`${styles.content} z-10`}>
        <Logo size={300} dark/>
        <div className="text-center text-2xl mt-5">
          <span className="text-white font-light">Stream and Earn.</span>
          <br/>
          <Link to="/panel/new">
            <Button className="bg-primary text-white text-xl mt-5" prevent={false}>
              <div className='px-10 py-1 flex items-center'>
                { user && <Icon name='person' className='mr-2' size='1.5rem'/> }
                { user ? `Continue as ${user.username}` : 'Quick Start' }
              </div>
            </Button>
          </Link>
        </div>
        {
          !user &&
          <>
            <hr className='white mt-12 border-2' style={{width: '250px'}}/>
            <div
              className='flex flex-col w-full text-white gap-5 px-2 mt-10'
              style={{maxWidth: '350px'}}
            >
              <div className='flex flex-col gap-1'>
                <span className='text-lg text-center'>Already have an account?</span>
                <Button onClick={() => setAuthView('login')} className='border border-white'>Login</Button>
              </div>
              <div className='flex flex-col gap-1'>
                <span className='text-lg text-center'>
                  New here - register now in 20 seconds*
                </span>
                <Button onClick={() => setAuthView('signUp')} className='border border-white'>Sign up</Button>
              </div>
              <span className='text-center'>
                *Register now and obtain all the benefits, like scheduling multiple streams.
              </span>
            </div>
          </>
        }
      </div>
      {
        authView && <Authentication view={authView} onLogged={onLogged} onClose={() => setAuthView('')} onQuickStart={onLogged}/>
      }
    </div>
  );
}
export default Home;
