import ShareButton from "../shareButton/ShareButton";
import { GetStreamUrl } from '../../utils/Text'

const ShareStreamButton = ({ streamId, className, ...props }) => {
	const url = GetStreamUrl(streamId);
	const message = `Join me on LifeStrm! ${url}`;

	return <ShareButton url={url} text={message} title='LifeStrm' className={className} {...props} />;
};

export default ShareStreamButton;