import { useState } from 'react';

export const useConfirmDialog = () => {
	const [show, setShow] = useState(false);
	const [info, setInfo] = useState({
		title: '',
		message: '',
		icon: 'check_circle',
		cancelButtonText: 'Cancel',
		confirmButtonText: 'Confirm',
		cancelButtonIcon: 'cancel',
		confirmButtonIcon: 'check_circle',
	});
	const [resolve, setResolve] = useState(null);
	const [reject, setReject] = useState(null);

	const open = ({title, message, confirmButtonText, cancelButtonText, icon, cancelButtonIcon, confirmButtonIcon}) => {
		setInfo({ title, message, icon, cancelButtonText, confirmButtonText, cancelButtonIcon, confirmButtonIcon });
		setShow(true);

		return new Promise(resolve => {
			setResolve(() => {
				return () => {
					setShow(false);
					resolve(true);
				};
			});
			setReject(() => {
				return () => {
					setShow(false);
					resolve(false);
				};
			});
		});
	};

	return {
		show,
		info,
		resolve,
		reject,
		open,
	};
};
