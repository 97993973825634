import { useEffect, useState } from 'react';
import Icon from '../icon/Icon';
import styles from './Alert.module.css';

export default function Alert({ message, type, show }) {
	const [icon, setIcon] = useState('');
	const [display, setDisplay] = useState(false);
	const [_timeout, _setTimeout] = useState(null);

	useEffect(() => {
		const icons = {
			success: 'check_circle',
			error: 'priority_high',
			warning: 'warning',
			info: 'info',
			challenge: 'directions_run',
		};

		setIcon(icons[type]);
	}, [type]);

	useEffect(() => {
		if(show) {
			setDisplay(true);
		} else {
			clearTimeout(_timeout);
			_setTimeout(setTimeout(() => {
				setDisplay(false);
			}, 5050));
		}
	}, [show]);

	return display ? (
		<div className={`${styles.alert} ${!show && 'opacity-0'} transition ease-in-out z-50` }>
			<Icon name={icon} size='2rem'/>
			{ message }
		</div>
	) : null;
}