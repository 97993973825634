import styles from './ChallengesList.module.css';
import Button from '../button/Button';
import Icon from '../icon/Icon';
import axios from '../../utils/axios';
import { useCallback, useEffect, useState } from 'react';
import { useGlobal } from '../../contexts/GlobalContext';
import { useStream } from '../../contexts/StreamContext';
import Challenge from '../challenge/Challenge';
import CreateChallengeDialog from '../../dialogs/createChallengeDialog/CreateChallengeDialog';
import IncreaseBidDialog from '../../dialogs/increaseBidDialog.jsx/IncreaseBidDialog';

const ChallengesList = ({ show, streamId, streamerView, onClose }) => {
	const { streamInfo, challenges, fetchChallenges } = useStream();
	const [userId, setUserId] = useState(null);
	const [selectedChallenges, setSelectedChallenges] = useState([]);
	const [showCreateChallengeDialog, setShowCreateChallengeDialog] = useState(false);
	const [increaseBidInfo, setIncreaseBidInfo] = useState({
		show: false,
		challengeId: null,
	});
	const { requestLogin, showAlert, getCurrentUser } = useGlobal();

	useEffect(() => {
		getCurrentUser().then(user => {
			if(user) {
				setUserId(user.id);
			}
		});
	}, []);

	useEffect(() => {
		fetchChallenges();
	}, [streamId]);

	useEffect(() => {
		setSelectedChallenges([]);
	}, [streamInfo.inChallengeMode]);

	const rejectChallenge = useCallback(async (challengeId) => {
		await axios.post(`/challenge/${challengeId}/reject`);
	}, []);

	const acceptChallenge = useCallback(async (challengeId) => {
		if(selectedChallenges.length >= 3) {
			showAlert('You can only accept 3 challenges at a time', 'error');
			return;
		}
		setSelectedChallenges([...selectedChallenges, challengeId]);
	}, [selectedChallenges]);

	const startChallengeMode = useCallback(async () => {
		if(selectedChallenges.length === 0) {
			showAlert('You need to select at least one challenge', 'error');
			return;
		}
		try {
			await axios.post(`/stream/${streamId}/challenge-mode`, {
				challenges: selectedChallenges,
			});
			showAlert('Challenge mode started!', 'success');
			onClose();
		} catch (error) {
			showAlert('Error starting challenge mode', 'error', error);
		}
	}, [selectedChallenges]);

	const stopChallengeMode = useCallback(async () => {
		try {
			await axios.post(`/stream/${streamId}/challenge-mode/stop`);
			showAlert('Challenge mode finished!', 'success');
		} catch (error) {
			showAlert('Error finishing challenge mode', 'error', error);
		}
	}, []);

	const startPoll = useCallback(async () => {
		try {
			await axios.post(`/stream/${streamId}/poll`);
			showAlert('Poll started!', 'success');
		} catch (error) {
			showAlert('Error starting poll', 'error', error);
		}
	}, []);

	const stopPoll = useCallback(async () => {
		try {
			await axios.post(`/stream/${streamId}/poll/stop`);
			showAlert('Poll finished!', 'success');
		} catch (error) {
			showAlert('Error finishing poll', 'error', error);
		}
	}, []);

	const vote = useCallback(async (challengeId, res) => {
		let challenge = challenges.find(challenge => challenge.id === challengeId);
		const logged = await requestLogin();
		if(!logged) return;
		const user = await getCurrentUser();
		if(user) {
			setUserId(user.id);
		}
		try {
			res = await axios.post(`/challenge/${challenge.id}/vote/${res ? 'true' : 'false'}`);
		} catch (error) {
			showAlert('Error voting', 'error', error);
		}
	}, [challenges]);

	const togglePin = useCallback(async (challengeId) => {
		try {
			await axios.post(`/challenge/${challengeId}/pin`);
		} catch (error) {
			showAlert('Error pinning challenge', 'error', error);
		}
	}, []);

	return (
		<div className={styles.container} style={{ display: show ? '' : 'none' }}>
			<Icon onClick={onClose} name='close' size='1.8rem' className='fixed top-1 right-1 z-10 cursor-pointer'/>

			<div className={styles.list}>
				<h1 className="text-lg text-center font-semibold">Bets & Challenges</h1>
				{
					streamerView ?
					<>
						<span>Are your viewers challenging you? Check it out!</span>
						{
							streamInfo.inChallengeMode ?
								<Button onClick={stopChallengeMode} className='bg-primary text-white rounded-lg mt-2 flex justify-center items-center w-full'>
									<Icon name='check_circle' size='1.1rem' className='mr-1'/>
									Finish Challenge!
								</Button>
								:
							streamInfo.inPoll ?
								<Button onClick={stopPoll} className='bg-primary text-white rounded-lg mt-2 flex justify-center items-center w-full'>
									<Icon name='how_to_vote' size='1.1rem' className='mr-1'/>
									Finish Poll!
								</Button>
								:
							challenges.filter(challenge => challenge.status === 'done').length ?
								<Button onClick={startPoll} className='bg-primary text-white rounded-lg mt-2 flex justify-center items-center w-full'>
									<Icon name='how_to_vote' size='1.1rem' className='mr-1'/>
									Launch Poll
								</Button>
								:
								<Button onClick={startChallengeMode} className='bg-primary text-white rounded-lg mt-2 flex justify-center items-center w-full'>
									<Icon name='check_circle' size='1.1rem' className='mr-1'/>
									Start Challenge!
								</Button>
						}
					</>
					:
					<>
						<span>Create your own challenge</span>
						<Button onClick={() => setShowCreateChallengeDialog(true)} className='bg-primary text-white rounded-lg mt-2 flex justify-center items-center w-full'>
							<Icon name='check_circle' size='1.1rem' className='mr-1'/>
							Throw Challenge!
						</Button>
					</>
				}

				<div className='flex flex-col gap-2 mt-4 w-full justify-center'>
					{
						!challenges.length &&
						<span className='text-lg italic'>No challenges yet!</span>
					}
					{
						challenges
							.filter(challenge => challenge.status === 'ready' && challenge.isPinned)
							.sort((a, b) => b.amount - a.amount)
							.map((challenge, ix) =>
								<Challenge
									onAccept={() => acceptChallenge(challenge.id)}
									onRemove={() => setSelectedChallenges(selectedChallenges.filter(id => id !== challenge.id))}
									onPin={() => togglePin(challenge.id)}
									onIncreaseBid={() => setIncreaseBidInfo({show: true, challengeId: challenge.id})}
									challenge={challenge} _key={ix+1} key={challenge.id}
									streamerView={streamerView} disabled={streamInfo.inChallengeMode}
									selected={selectedChallenges.includes(challenge.id)}
								/>
							)
					}
					{
						challenges
							.filter(challenge => challenge.status === 'accepted' || challenge.status === 'done')
							.sort((a, b) => b.amount - a.amount)
							.map((challenge, ix) =>
								<Challenge
									userId={userId}
									challenge={challenge} _key={ix+1} key={challenge.id}
									streamerView={streamerView} inPoll={streamInfo.inPoll}
									onVote={e => vote(challenge.id, e)}
								/>
							)
					}
					{
						challenges
							.filter(challenge => selectedChallenges.includes(challenge.id) && !challenge.isPinned)
							.sort((a, b) => b.amount - a.amount)
							.map((challenge, ix) =>
								<Challenge
									onRemove={() => setSelectedChallenges(selectedChallenges.filter(id => id !== challenge.id))}
									onPin={() => togglePin(challenge.id)}
									challenge={challenge} _key={ix+1} key={challenge.id}
									selected streamerView={streamerView}
								/>
							)
					}
					{
						challenges
							.filter(challenge => !selectedChallenges.includes(challenge.id) && challenge.status === 'ready' && !challenge.isPinned)
							.sort((a, b) => b.amount - a.amount)
							.map((challenge, ix) =>
								<Challenge
									onReject={() => rejectChallenge(challenge.id)}
									onAccept={() => acceptChallenge(challenge.id)}
									onPin={() => togglePin(challenge.id)}
									challenge={challenge} _key={ix+1} key={challenge.id}
									streamerView={streamerView} disabled={streamInfo.inChallengeMode}
								/>
							)
					}
				</div>

			</div>

			<CreateChallengeDialog show={showCreateChallengeDialog} onClose={() => setShowCreateChallengeDialog(false)}/>
			<IncreaseBidDialog show={increaseBidInfo.show} onClose={() => setIncreaseBidInfo(v => ({...v, show: false}))} challengeId={increaseBidInfo.challengeId}/>
		</div>
	);
};

export default ChallengesList;