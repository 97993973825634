import ReactCalendar from 'react-calendar';
import TextInput from '../text-input/TextInput';
import moment from 'moment';
import { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';

const DatePicker = ({ value, onChange }) => {
	const getDateString = date => moment(moment(date || undefined).format('YYYY-MM-DD')).toISOString();
	const [date, setDate] = useState(getDateString(value));
	const [dialog, setDialog] = useState({
		isOpen: false,
		x: 0,
		y: 0
	});

	const open = e => {
		e.stopPropagation();
		setDialog({
			isOpen: true,
			x: e.clientX,
			y: e.clientY
		});
	};

	const _onChange = date => {
		setDate(getDateString(date));
		onChange(moment(date).format('YYYY-MM-DD'));
		setDialog({ isOpen: false });
	};

	useEffect(() => {
		window.addEventListener('click', () => setDialog({ isOpen: false }));
		return () => window.removeEventListener('click', () => setDialog({ isOpen: false }));
	}, []);

	return (
		<div className='relative'>
			<TextInput onClick={open} value={moment(date).format('DD/MM/YYYY')} readonly/>
			{
				dialog.isOpen &&
				<div onClick={e => { e.stopPropagation(); e.preventDefault(); }} className='absolute z-50' style={{ left: 0, top: '3rem' }}>
					<ReactCalendar
						value={date} onChange={_onChange}
					/>
				</div>
			}
		</div>
	);
};

export default DatePicker;