import Menu from "../menu/Menu";
import phoneCodes from "../../json/phoneCodes";
import { useEffect, useState } from "react";

const PhoneInput = ({ value, onInput, ...props }) => {
	const [selected, setSelected] = useState(phoneCodes[0]);
	const [_value, setValue] = useState(value);

	const menu = (
		<div className="max-h-80 overflow-y-auto dense-scrollbar">
			{
				phoneCodes.map((code, i) => (
					<div onClick={() => setSelected(code)} key={i} className="flex items-center justify-between pr-2 gap-1">
						<div>{code.dial_code}</div>
						<div>{code.code}</div>
					</div>
				))
			}
		</div>
	);

	useEffect(() => {
		onInput(selected.dial_code + ' ' + _value);
	}, [selected]);

	const handleInput = e => {
		setValue(e.target.value);
		if(onInput) onInput(selected.dial_code + ' ' + e.target.value);
	};

	return (
		<div className="flex items-center border border-black rounded-xl px-1">
			<Menu menu={menu} className='hover:bg-gray-100 pl-2 rounded cursor-pointer'>
				<span>{selected.code} <b>{selected.dial_code}</b></span>
			</Menu>
			<input
				value={_value}
				onInput={handleInput}
				type='tel'
				placeholder='Phone'
				className='border-none grow focus:outline-none outline-none border-transparent focus:border-transparent focus:ring-0'
				{...props}
			/>
		</div>
	);
};

export default PhoneInput;