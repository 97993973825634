import Logo from '../logo/Logo';
import styles from './LoadingScreen.module.scss';

const LoadingScreen = () => {
	return (
		<div className="fixed left-0 top-0 h-dscreen w-dscreen flex items-center justify-center z-50" style={{ background: '#000000cc' }}>
			<Logo mode='default' size='200px'/>
			<div className={styles.load}></div>
		</div>
	);
};

export default LoadingScreen;