import MainPanel from "../../components/mainPanel/MainPanel";
import Icon from "../../components/icon/Icon";
import Button from "../../components/button/Button";
import { useGlobal } from '../../contexts/GlobalContext';
import { useEffect, useState } from "react";
import TextInput from "../../components/text-input/TextInput";
import axios from '../../utils/axios';
import { useNavigate } from "react-router-dom";

const Profile = () => {
	const { getCurrentUser, showAlert } = useGlobal();
	const [user, setUser] = useState({});
	const [paymentMethod, setPaymentMethod] = useState(null);
	const navigate = useNavigate();	

	useEffect(() => {
		getCurrentUser()
			.then(res => {
				setUser(res);
			});
		axios.get('user/payment-method')
			.then(res => {
				setPaymentMethod(res.data);
			})
			.catch(err => {
				showAlert('Cannot get payment method', 'error', err);
			});
	}, []);

	const handleLogout = () => {
		localStorage.removeItem('token');
		navigate('/');
	};

	const handleDeletePaymentMethod = async () => {
		try {
			await axios.delete('user/payment-method');
			setPaymentMethod({});
			showAlert('Payment method deleted successfully', 'success');
		} catch (err) {
			showAlert('Cannot delete payment method', 'error', err);
		}
	};

	return (
		<MainPanel onPrev={() => navigate('/panel/new')}>
			<div className="flex mb-5">
				<Icon name='edit_square' className='text-primary' size='2rem'/>
				<div className="ml-2">
					<h1 className="text-2xl font-bold">Profile Info</h1>
					<p className="text-gray-500">Logout from your account!</p>
				</div>
			</div>
			<div className="flex flex-col gap-3 mb-5">
				<label>
					<span className="uppercase font-semibold italic">Username</span> <br />
					<TextInput value={`@${user.username || ''}`} className="bg-gray-100" readonly/>
				</label>
				<label>
					<span className="uppercase font-semibold italic">Email</span> <br />
					<TextInput value={user.email || ''} className="bg-gray-100" readonly/>
				</label>
				<label>
					<span className="uppercase font-semibold italic">Password</span> <br />
					<TextInput value='*********' className="bg-gray-100" readonly/>
				</label>
				<Button onClick={handleLogout} className='bg-primary text-white mx-16 mt-3'>Logout</Button>
				<hr className="border mx-24 mt-5"/>
			</div>
			<div className="flex flex-col gap-3">
				<div>
					<h1 className="text-2xl font-bold">Payment Method</h1>
					<p className="text-gray-500">Check your info or delete it!</p>
				</div>
				{ !paymentMethod && 
						<span className="text-center bg-primary-light text-white">
							No payment method found.
						</span>
				}
				{
					paymentMethod && !paymentMethod.card &&
					<>
						<span className="text-center bg-primary-light text-white">
							The payment method is not a card.
						</span>
						<Button onClick={handleDeletePaymentMethod} className='bg-primary text-white mt-3 flex items-center justify-center gap-3'>
							<Icon name='delete'/>
							Delete My Payment Method
						</Button>
					</>
				}
				{
					paymentMethod?.card && 
					<>
						<label>
							<span className="uppercase font-semibold italic">Cardholder Name</span> <br />
							<TextInput value={'John Doe'} className="bg-gray-100" readonly/>
						</label>
						<label>
							<span className="uppercase font-semibold italic">Card Information</span> <br />
							<div className="flex w-full">
								<TextInput value={`${paymentMethod.card.brand.toUpperCase()}   **** **** **** ${paymentMethod.card.last4}`} className="bg-gray-100 rounded-r-none" contClassName='grow' readonly/>
								<TextInput value={`Exp. Date: ${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`} className="bg-gray-100 rounded-l-none" contClassName='grow' readonly/>
							</div>
						</label>
						<Button onClick={handleDeletePaymentMethod} className='bg-primary text-white mt-3 flex items-center justify-center gap-3'>
							<Icon name='delete'/>
							Delete My Payment Method
						</Button>
					</>
				}
			</div>
		</MainPanel>
	);
};

export default Profile;