import axios from 'axios';

const instance = axios.create({ baseURL: process.env.REACT_APP_ROOT_API });
instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['authorization'] = `Bearer ${token}`;
  }
  return config;
},
(error) => {
  Promise.reject(error);
});

export default instance;