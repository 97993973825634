import Dialog from "../dialog/Dialog";
import Button from "../../components/button/Button";
import Icon from "../../components/icon/Icon";
import TextInput from '../../components/text-input/TextInput';
import axios from "../../utils/axios";
import { useEffect, useRef, useState } from "react";
import { UpdateObject } from '../../utils/Form';
import { useGlobal } from "../../contexts/GlobalContext";
import { useStream } from "../../contexts/StreamContext";

const CreateChallengeDialog = ({ show, onClose }) => {
	const [newChallenge, setNewChallenge] = useState({
		description: '',
		amount: '',
	});
	const form = useRef(null);
	const { requestLogin } = useGlobal();
	const { streamInfo } = useStream();

	useEffect(() => {
		if(!show) {
			setNewChallenge({
				description: '',
				amount: '',
			});
		}
	}, [show]);

	const sendChallenge = async (e) => {
		if(!form.current.reportValidity()) return;
		const logged = await requestLogin();
		if(!logged) return;

		const res = await axios.post('/challenge', {
			streamId: streamInfo.id,
			...newChallenge,
		});
		const url = res.data;
		window.location.href = url;
	};

	return (
		<Dialog show={show} onClose={onClose} zIndex={50} maxWidth='400px' persistent>
			<div className="flex flex-col gap-3">
				<div className="flex items-center gap-3 border-b border-secondary pb-2">
					<Icon name='sprint' className='text-primary' size='3rem'/>
					<div>
						<span className="text-2xl font-bold">Throw a Challenge!</span>
						<br />
						<span className="text-secondary-light">Challenge <span className="text-primary">@{streamInfo.ownerUsername}</span></span>
					</div>
				</div>
				<form ref={form} className="flex flex-col gap-3">
					<label>
						<span className='font-semibold uppercase italic'>How much is your bet?</span>
						<TextInput
							value={newChallenge.amount} onInput={(e) => UpdateObject(e, setNewChallenge)}
							placeholder='$0.00 USD' className='w-32' name='amount'
							type='number' required
						/>
					</label>
					
					<label>
						<span className='font-semibold uppercase italic'>What's this about?</span>
						<textarea
							value={newChallenge.description} onInput={(e) => UpdateObject(e, setNewChallenge)}
							className='w-full rounded-xl' placeholder='Describe your challenge...' rows="3"
							name='description' required
						/>
					</label>

					<div className="flex gap-3">
						<Button onClick={onClose} className='border border-primary text-primary grow'>Cancel</Button>
						<Button onClick={sendChallenge} className='bg-primary text-white grow' type="submit">Challenge!</Button>
					</div>
				</form>
			</div>
		</Dialog>
	);
};

export default CreateChallengeDialog;