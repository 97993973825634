import { useEffect, useState } from "react";

const Menu = ({ children, menu, className, position = 'bottom' }) => {
	const [showMenu, setShowMenu] = useState(false);
	const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

	const openMenu = e => {
		e.stopPropagation();
		e.preventDefault();
		setShowMenu(true);
		
		if(position === 'bottom') {
			setMenuPosition({
				x: '0',
				y: '100%'
			});
		}
	};

	useEffect(() => {
		window.addEventListener('click', () => setShowMenu(false));
		return () => window.removeEventListener('click', () => setShowMenu(false));
	}, []);

	return (
		<div onClick={openMenu} className={`_menu relative ${className}`}>
			{children}
			{
				showMenu &&
				<div
					style={{
						position: 'absolute',
						top: menuPosition.y,
						left: menuPosition.x,
					}}
					className={`bg-white rounded-md shadow-md p-2 pr-1 z-50`}
				>
					{menu}
				</div>
			}
		</div>
	);
};

export default Menu;