import TextInput from '../text-input/TextInput';
import Menu from '../menu/Menu';
import Icon from '../icon/Icon';
import { useEffect, useState } from 'react';
import { ZeroPad } from '../../utils/Text';
import styles from './TimePicker.module.scss';

const TimePicker = ({value, onChange}) => {
	let _hours, _minutes, _amPm, _;
	if(value) {
		[_hours, _] = value.split(':');
		[_minutes, _amPm] = _.split(' ');
	} else {
		_hours = 12;
		_minutes = 0;
		_amPm = 'AM';
	}

	const [hours, setHours] = useState(_hours);
	const [minutes, setMinutes] = useState(_minutes);
	const [amPm, setAmPm] = useState(_amPm);

	useEffect(() => {
		if(onChange)
			onChange(`${hours}:${ZeroPad(minutes, 2)} ${amPm}`);
	}, [hours, minutes, amPm]);

	const incrementHours = (add = 1) => {
		let newHours = parseInt(hours) + add;
		if(newHours > 12) newHours = 1;
		else if(newHours < 1) newHours = 12;
		setHours(newHours);
	};

	const incrementMinutes = (add = 1) => {
		let newMinutes = parseInt(minutes) + add;
		if(newMinutes > 59) newMinutes = 0;
		else if(newMinutes < 0) newMinutes = 55;
		setMinutes(newMinutes);
	};

	const toggleAmPm = () => {
		if(amPm === 'AM') setAmPm('PM');
		else setAmPm('AM');
	};

	

	const clockLayout = (
		<div className={styles.container}>
			<div>
				<Icon onClick={() => incrementHours(1)} name='keyboard_arrow_up' className='cursor-pointer' size='1.3rem'/>
				<span>{ hours }</span>
				<Icon onClick={() => incrementHours(-1)} name='keyboard_arrow_down' className='cursor-pointer' size='1.3rem'/>
			</div>
			<span className='text-semibold text-lg'>:</span>
			<div>
				<Icon onClick={() => incrementMinutes(5)} name='keyboard_arrow_up' className='cursor-pointer' size='1.3rem'/>
				<span>{ ZeroPad(minutes, 2) }</span>
				<Icon onClick={() => incrementMinutes(-5)} name='keyboard_arrow_down' className='cursor-pointer' size='1.3rem'/>
			</div>
			<div>
				<Icon onClick={toggleAmPm} name='keyboard_arrow_up' className='cursor-pointer' size='1.3rem'/>
				<span>{ amPm }</span>
				<Icon onClick={toggleAmPm} name='keyboard_arrow_down' className='cursor-pointer' size='1.3rem'/>
			</div>
		</div>
	);

	return (
		<Menu menu={ clockLayout }>
			<TextInput value={value} readonly/>
		</Menu>
	);
};

export default TimePicker;