import { useStream } from "../../contexts/StreamContext";
import axios from "../../utils/axios";
import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { create, PlayerState } from 'amazon-ivs-player';
import Icon from "../../components/icon/Icon";
import Button from "../../components/button/Button";
import Logo from "../../components/logo/Logo";
import { useGlobal } from "../../contexts/GlobalContext";
import moment from "moment";
import styles from './StreamWatch.module.css';

const createAbsolutePath = (assetPath) => new URL(assetPath, document.URL).toString();

const StreamWatch = () => {
	const videoNode = useRef(null);
	const [player, setPlayer] = useState(null);
	const [muted, setMuted] = useState(true);
	const [timerActive, setTimerActive] = useState(false);
	const [remainingTime, setRemainingTime] = useState('');
	const [remainingTimeInterval, setRemainingTimeInterval] = useState(null);
	const [showControls, setShowControls] = useState(false);
	const { id } = useParams();
	const navigate = useNavigate();
	const { streamInfo, subscribed, keepWatching, setCloseStream, setShowChat, setOnPreviewFinished, setIsPaused } = useStream();
	const { user, showConfirmDialog, requestLogin, showAlert } = useGlobal();

	useEffect(() => {
		setIsPaused(!timerActive);
	}, [timerActive, setIsPaused]);

	useEffect(() => {
		if(streamInfo.started || streamInfo.finished || !streamInfo.isScheduled || streamInfo.scheduledDateTime < moment().valueOf()) {
			setRemainingTime('');
			remainingTimeInterval && clearInterval(remainingTimeInterval);
			return;
		}

		const computeRemainingTime = () => {
			const now = moment().valueOf();
			const diff = streamInfo.scheduledDateTime - now;
			const seconds = Math.floor(diff/1000);
			const hours = Math.floor(seconds/60/60);
			const minutes = Math.floor(seconds/60%60);

			if(diff < 0) {
				setRemainingTime('');
				remainingTimeInterval && clearInterval(remainingTimeInterval);
				return;
			}
			setRemainingTime(`${hours}:${minutes < 10 ? '0' : ''}${minutes}`);
		};
		computeRemainingTime();
		const interval = setInterval(computeRemainingTime, 1000 * 60);
		remainingTimeInterval && clearInterval(remainingTimeInterval);
		setRemainingTimeInterval(interval);
	}, [streamInfo]);

	useEffect(() => {
		if(player || !videoNode.current) return;
		const _player = create(
			{
				wasmWorker: createAbsolutePath('../amazon-ivs-wasmworker.min.js'),
				wasmBinary: createAbsolutePath('../amazon-ivs-wasmworker.min.wasm'),
			}
		);
		_player.attachHTMLVideoElement(videoNode.current);
		_player.setMuted(true);
		_player.setAutoplay(true);

		axios.get(`/stream/${id}`)
			.then(res => {
				const playbackUrl = res.data.playbackUrl;
				_player.addEventListener('Playing', () => {
					setTimerActive(true);
				});
				_player.addEventListener('Ended', () => {
					setTimerActive(false);
				});
				_player.addEventListener('PlayerError', () => {
					setTimeout(() => {
						_player.load(playbackUrl);
					}, 3000);
					setTimerActive(false);
				});

				_player.load(playbackUrl);
				
				setPlayer(_player);

				// only show controls on ios
				const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
				setShowControls(isIOS);

				return () => {
					if (player !== null) {
						player.delete();
					}
				};
			})
			.catch(err => {
				console.error(err);
			});
    
  }, [player, id, streamInfo.started, streamInfo.finished, videoNode]);
	
	useEffect(() => {
		const handleEnd = () => {
			player.pause();
		};
		const endStream = () => {
			navigate('/panel/new');
		};
		setOnPreviewFinished(() => handleEnd);
		setShowChat(true);
		setCloseStream(() => endStream);
	}, [setCloseStream, setShowChat, setOnPreviewFinished, navigate, player]);

	const subscribe = async () => {
		const res = await showConfirmDialog({
			title: 'Do you want to be notified and get a reminder for this stream?',
			message: 'An SMS will be sent to you a day before, and an hour before this event happens. Get ready!',
			confirmButtonText: 'Notify!',
			cancelButtonText: 'Cancel',
			confirmButtonIcon: 'send',
			cancelButtonIcon: 'close',
		});
		if(!res) return;

		const withPhone = await requestLogin();
		if(!withPhone) return;

		try {
			await axios.post(`/stream/${id}/subscribe`);
			showAlert('You will be notified when the stream starts', 'success');
		} catch (error) {
			showAlert(error.response.data.message || 'Something went wrong', 'error');
		}
	};

	return (
		<>
			{
				streamInfo.started && !streamInfo.finished &&
				<div className="absolute left-1/2 -translate-x-1/2" style={{width: '100dvw'}}>
					{
						player &&
						<Button
							onClick={() => { player.setMuted(!muted); setMuted(!muted); }}
							className='absolute left-3 top-3 z-30'
							icon
						>
							<Icon name={muted ? 'volume_off' : 'volume_up'} size='2.5rem' className='text-white'/>
						</Button>
					}
					<video ref={videoNode} className="w-full max-h-screen" playsInline controls={showControls}/>
				</div>
			}
			{
				!streamInfo.started && !streamInfo.finished &&
				(
					remainingTime ? 
					<div className={styles['counter-container']}>
						<div className={styles.gradient}></div>
						<div className="z-30 flex flex-col items-center gap-2 pt-10" style={{maxWidth: '700px'}}>
							<Logo mode='dark'/>
							<span className="text-3xl font-semibold text-center mt-5">{streamInfo.name}</span>
							{
								streamInfo.description ?
								<span className="text-xl mb-2 text-gray-100 text-center px-3">{streamInfo.description}</span>
								:
								<span className="text-3xl font-semibold text-center my-5">Get ready for what's coming!</span>
							}
							<span className="text-xl text-center">Stream will start in:</span>
							<b className="text-5xl mb-5">{remainingTime}h</b>
							{
								(subscribed && user) ?
								<div className="flex flex-col items-center gap-2 px-2">
									<span className="text-xl font-semibold">Thanks @{user.username}!</span>
									<span className="text-gray-200 text-center">You'll be notified a day before, and an hour before the stream, so get ready!</span>
								</div>
								:
								<Button
									onClick={subscribe}
									className={`${streamInfo.preview ? 'border border-primary' : 'bg-primary'} flex items-center gap-1`}
								>
									Notify Me!
								</Button>
							}
							{
								(streamInfo.preview) &&
								<Button onClick={keepWatching} className='bg-primary text-white text-xl flex items-center gap-1 mt-2'>
									Buy Ticket
								</Button>
							}
						</div>
					</div>
					:
					<div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20 text-white flex flex-col items-center gap-2">
						<Logo/>
						<span className="text-xl text-center">Stream will start soon</span>
					</div>
				)
			}
			{
				streamInfo.finished &&
				<div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20 text-white flex flex-col items-center gap-2">
					<Logo/>
					<span className="text-xl">Stream has ended</span>
				</div>
			}
		</>
	);
};

export default StreamWatch;